import React from 'react';
import twitterLogo from "../../../assets/img/footer/logo-twitter-white.svg";

const twitterButton = props => {
    return (
        <React.Fragment>
            {
                props.href &&
                <a className="no-click-out-icon" href={props.href + '?lang=' + props.locale} target="_blank" rel="noopener noreferrer">
                    <img src={twitterLogo} alt="twitter" />
                </a>
            }
        </React.Fragment>
        );
}

export default twitterButton;
