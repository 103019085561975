import React from 'react'
import { FormattedMessage } from 'react-intl';
import Select from 'react-select'
import './countryLine.css'

import { getCountries } from '../../../translations/address'

const countryLine = props => {

    const countries = getCountries(props.locale)
    
    return (
        <div className='input-wrapper'>
            <label htmlFor='country'>
                <FormattedMessage id="create.country"
                    defaultMessage="Country"
                    description="Country input"
                />&nbsp;<span className='mandatory-field'>*</span></label>
            <Select name='country-select'
                options=
                {
                    countries.map(function(e) { return {value:e[0],label:e[1]} })
                }
                className={props.className}
                value={props.value}
                onChange={props.onChange}
                noResultsText={null}
                placeholder={null}
                multi={false}
                required={true}
                />
        </div>
    )
}

export default countryLine
