import React from 'react'
import { FormattedMessage } from 'react-intl'
import CountryLine from './countryLine'
import StateLine from './stateLine'

const noTrailingBlanksPattern = "^\\S+(?:\\s+\\S+)*$"

const cityLine = props => {

    return (
        <div className='input-wrapper'>
            <label htmlFor='city'>
                <FormattedMessage id="create.city"
                    defaultMessage="City"
                    description="City input"
                />&nbsp;<span className='mandatory-field'>*</span>
            </label>
            <input
                type='text'
                className={props.className}
                id='city'
                onChange={props.onChange}
                maxLength='40'
                value={props.value}
                autoComplete="address-level2"
                pattern={noTrailingBlanksPattern}
                required
            />
        </div>
    )
}

const adressLine = props => {

    return (
        <div className='input-wrapper'>
            <label htmlFor='address'>
                <FormattedMessage id="create.address"
                    defaultMessage="Address"
                    description="Address input"
                />&nbsp;<span className='mandatory-field'>*</span>
            </label>
            <textarea
                name='address'
                className={props.className}
                id='address'
                onChange={props.onChange}
                value={props.value}
                maxLength='255'
                cols='33'
                rows='4'
                autoComplete="street-address"
                pattern={noTrailingBlanksPattern}
                required
            >
            </textarea>
        </div>
    )
}

const zipLine = props => {

    return (
        <div className='input-wrapper'>
            <label htmlFor='postal_code'>
                <FormattedMessage id="create.postalCode"
                    defaultMessage="Zip code"
                    description="Postal code input"
                />&nbsp;<span className='mandatory-field'>*</span>
                </label>
            <input
                type='text'
                className={props.className}
                id='postal_code'
                value={props.value}
                maxLength='20'
                onChange={props.onChange}
                autoComplete="postal-code"
                pattern={noTrailingBlanksPattern}
                required
            />
        </div>
    )
}

const postalBlock = props => {

    let city=null, address=null, zip=null

    if (props.cfg.postal) {
        city = cityLine({ value:props.values.city, className:props.classes.city, onChange: props.onChange})
        address = adressLine( {value:props.values.address, className:props.classes.address, onChange: props.onChange})
        zip = zipLine({ value:props.values.postal_code, className:props.classes.postal_code, onChange: props.onChange})
    }

    return (
        <React.Fragment>
            <CountryLine locale={props.locale}
                value={props.values.country} 
                onChange={props.onCountryChanged}
                className={props.classes.country} />
            <StateLine country={props.values.country}
                locale={props.locale}
                value={props.values.state}
                onChange={props.onStateChanged}
                className={props.classes.state}
                required={props.classes.state !== ''} />
            {city}
            {address}
            {zip}
        </React.Fragment>
    )
}

export default postalBlock
