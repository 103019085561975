import React from 'react';
import { FormattedMessage } from 'react-intl';

const contactButton = props => {
    return (
        <React.Fragment>
            {
                props.cfg.contact &&
                <div className="contact-button-wrapper">
                    <a href={props.cfg.contact[props.locale]}>
                        <FormattedMessage id="header.contact"
                            defaultMessage="Contact us"
                            description="Contact button text"
                        />
                    </a>

                </div>
            }
        </React.Fragment>
    )
}

export default contactButton;