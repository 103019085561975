import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha';


export const emailLine = props => {

    return (
        <div className='input-wrapper'>
            <label htmlFor='email'>
                <FormattedMessage id="main.email"
                    defaultMessage="Email"
                    description="Email input"
                />&nbsp;<span className='mandatory-field'>*</span>
            </label>
            <input
                type='email'
                id='email'
                onChange={props.onChange}
                maxLength='80'
                value={props.value}
                autoComplete='email'
                required
                className={props.className}
                ref={props.emailRef}
            />
        </div>
    )
}

export const passwordBlock = props => {

    return (
        <React.Fragment>
            <div className='input-wrapper'>
                <label htmlFor='password'>
                    <FormattedMessage id="main.password"
                        defaultMessage="Password"
                        description="Password input"
                    />&nbsp;<span className='mandatory-field'>*</span></label>
                <input
                    type='password'
                    id='password'
                    className={props.classes.password}
                    onChange={props.onChange}
                    maxLength='70'
                    value={props.values.password}
                    autoComplete="new-password"
                    required
                />
            </div>
            <div className='input-wrapper'>
                <div className='tooltip'>
                    <FormattedMessage id="main.passwordTip"
                        defaultMessage="At least 8 characters including uppercase and lowercase, 1 number and 1 special character"
                        description="Password tip label"
                    />
                </div>
            </div>
            <div className='input-wrapper'>
                <label htmlFor='passwordConfirm'>
                    <FormattedMessage id="main.passwordConfirmation"
                        defaultMessage="Password confirmation"
                        description="Password confirmation input"
                    />&nbsp;<span className='mandatory-field'>*</span></label>
                <input
                    type='password'
                    id='passwordConfirm'
                    className={props.classes.passwordConfirm}
                    onChange={props.onChange}
                    maxLength='70'
                    value={props.values.passwordConfirm}
                    required
                />
            </div>
        </React.Fragment>
    )
}

const reCaptcha = props => {

    return (
        <div className='recaptcha'>
            <ReCAPTCHA
                ref={props.captchaRef}
                sitekey={props.sitekey}
                onChange={props.onCaptchaChange}
            />
        </div>
    )
}

const errorMessage = props => {
    return (
        <div className="error-message">{props.error}</div>
    )
}

const buttons = props => {

    return (
        <div className='buttons-wrapper'>
            <button type='submit' ref={props.submitRef} disabled={props.disableButtons}>
                <FormattedHTMLMessage id={props.submitMessage}
                    defaultMessage="Submit"
                    description="Submit button text"
                />
            </button>
            <button id='cancel' className='button--cancel' onClick={props.onCancel} ref={props.cancelRef} disabled={props.disableButtons} >
                <FormattedHTMLMessage id={props.cancelMessage}
                    defaultMessage="Cancel"
                    description="Cancel button text"
                />
            </button>
        </div>
    )
}

const mandatory = props => {
    return (
        <div className='help-tools-wrapper'>
            <span className='mandatory'>
                <FormattedHTMLMessage id="main.mandatory"
                    defaultMessage="* Mandatory fields"
                    description="Mandatory fileds message"
                />
            </span>
        </div>
    )
}

export const commonCaptchaFormWidgets = props => {
    return (
        <React.Fragment>
            { reCaptcha(props) }
            { errorMessage(props) }
            { buttons(props) }
            { mandatory(props) }
        </React.Fragment>
    )
}
