import React from 'react'
import { FormattedMessage } from 'react-intl';

const noTrailingBlanksPattern = "^\\S+(?:\\s+\\S+)*$"

const lastNameLine = props => {

    return (
        <div className='input-wrapper'>
            <label htmlFor='last_name'>
            <FormattedMessage id="create.lastName"
                defaultMessage="Last name"
                description="Last name input"
                />&nbsp;<span className='mandatory-field'>*</span>
            </label>
            <input
                id='last_name'
                type='text'
                ref={props.ref}
                className={props.className}
                pattern={noTrailingBlanksPattern}
                maxLength='40'
                onChange={props.onChange}
                value={props.value}
                autoComplete="family-name"
                required
            />
        </div>
    )
}

const firstNameLine = props => {

    return (
        <div className='input-wrapper'>
            <label htmlFor='first_name'>
            <FormattedMessage id="create.firstName"
                defaultMessage="First name"
                description="First name input"
                />&nbsp;<span className='mandatory-field'>*</span>
            </label>
            <input
                id='first_name'
                type='text'
                className={props.className}
                pattern={noTrailingBlanksPattern}
                maxLength='40'
                onChange={props.onChange}
                value={props.value}
                autoComplete="given-name"
                required
            />
        </div>            
    )
}

const nameBlock = props => {

    const lastName = lastNameLine({
        ref: props.lastNameRef, 
        value: props.values.last_name, 
        className: props.classes.last_name, 
        onChange: props.onChange
    })
    
    const firstName = firstNameLine({
        value: props.values.first_name,
        className: props.classes.first_name,
        onChange: props.onChange
    })
    
    return (
            <React.Fragment>
                {lastName}
                {firstName}
            </React.Fragment>
        )
}

export default nameBlock