import config from "./config"

/**
 * Get the application configuration
 * - Load defaults first
 * - Overwrite with a specific configuration if requested
 */
function getConfig(parsed) {

    let cfg = {}
    for (let k in config.default) cfg[k] = config.default[k]
 
    if ('cfg' in parsed) {
        let siteCfg = config[parsed.cfg]
        if (!siteCfg) console.warn('Configuration not found: ' + parsed.cfg)
        else for (let k in siteCfg) cfg[k] = siteCfg[k]
    }

    cfg['proxy'] = process.env.REACT_APP_STAGE_PROXY;
    cfg['captcha'] = process.env.REACT_APP_STAGE_CAPTCHA;

    return cfg
}

export default getConfig;
