import React from 'react'
import { FormattedHTMLMessage } from 'react-intl';

const termsLine = props => {

    return (
        <div className='checkbox-wrapper'>
            <input
                type='checkbox'
                id='agree'
                name='agree'
                checked={props.checked}
                onChange={props.onChange}
                required
            />
            <label htmlFor='agree' className='checkbox-label'>
                <FormattedHTMLMessage id="create.agree"
                    defaultMessage="I agree with Aldebaran <a href='https://www.aldebaran.com/en/term-use'>Terms of use</a> and <a href='https://www.aldebaran.com/en/privacy-policy'>Privacy Policy</a>"
                    description="Agree message"
                /><span className='mandatory-field'>&nbsp;*</span>
            </label>
        </div>
    )
}

export default termsLine
