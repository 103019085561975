const config =
{
    "default": {
        "main": process.env.REACT_APP_STAGE_SITE,
        "contact": {
            "en": process.env.REACT_APP_STAGE_SITE + "/emea/en/support",
            "fr": process.env.REACT_APP_STAGE_SITE + "/emea/fr/support",
            "ja": process.env.REACT_APP_STAGE_SITE + "/emea/en/support"
        },
        "langbar": true,

        "company": true,
        "postal": false,
        "city": true,
        "zip": true,
        "address": true,

        "social": true,
        "facebook": "https://www.facebook.com/AldebaranRobotics",
        "twitter": "https://twitter.com/AldebaranRobots",
        "instagram": "https://www.instagram.com/aldebaranrobots/",
        "youtube": "https://www.youtube.com/user/AldebaranRobotics",
        "linkedin": "https://www.linkedin.com/company/united-robotics-group/",
        "medium": false,

        "newsletter": true,
        "redirect": process.env.REACT_APP_STAGE_SITE
    }
}

export default config;
