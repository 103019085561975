import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';

import EmailCaptchaForm from '../Common/captchaForm'


class Forgot extends EmailCaptchaForm {


    buildEmptyState(state) {
        return {
            ...state,
            emailSent: false
        }
    }

    componentDidMount() {
        if (!this.state.success) {
            this.defaultControl.focus();
        }
    }

    getSubmitInfo() {
        return { url: this.props.cfg.proxy + 'password/', verb: 'patch' } 
    }

    getFormData() {
        return {
            ...super.getFormData(),
            lang: this.props.intl.locale
        }
    }

    onSucceeded() {
        this.setState({ emailSent: true })
    }

    render() {

        let section=''

        if (this.state.emailSent) {
            section =
                <FormattedHTMLMessage id="forgot.message"
                defaultMessage="Thank you, an email has been sent to your inbox."
                description="Mail sent" />
        } else {
            section = 
                <div className='section-wrapper long-desc-wrapper'>
                    <section name='forgot'>
                        <h2>
                            <FormattedMessage id="forgot.header"
                                defaultMessage="Please enter your email address below. You will receive a link to reset your password."
                                description="Header title on app forgot password page"
                            />
                        </h2>
                        <form onSubmit={this.onSubmitHandler} className='form'>
                            <div className="form-content-wrapper">
                                { this.emailLine(true) }
                                { this.commonWidgets('forgot') }
                            </div>
                        </form>
                    </section>
                </div>
        }

        return (
            <div className='Main'>
                <div className='main-wrapper'>
                    <h1>
                        <FormattedMessage id="forgot.title"
                            defaultMessage="Forgot your password?"
                            description="Main title on app forgot password page"
                        />
                    </h1>
                    {section}
                </div>
            </div>
        )
    }
}

export default injectIntl(Forgot)