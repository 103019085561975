import React from 'react'
import bcrypt from 'bcryptjs';

import { codePointCat } from './unicat'
import EmailCaptchaForm from './captchaForm'
import { passwordBlock as PasswordBlock } from './UI/common'


export function isPasswordValid(password) {
    
    if (!password || password.length < 8)
        return false
   
    function countCategories(password) {
        let count = {}
        for (let i=0; i<password.length; ++i) {
            const cat = codePointCat(password.charAt(i).charCodeAt())
            if (cat in count) count[cat]++
            else count[cat] = 1
        }
        return count
    }

    let count = countCategories(password)

    function hasLetterCase(lc) {
        return count[lc] > 0 || (count['O'] > 0 && (count['O']-- > 0))
    }

    return count.N > 0 && count.S > 0 && hasLetterCase('U') && hasLetterCase('L')
}


class PasswordForm extends EmailCaptchaForm {

    buildClassNamer(namer) {
        return super.buildClassNamer({ ...namer,
            password: (v) => { return isPasswordValid(v) ? 'valid' : 'invalid'  },
            passwordConfirm: (v, r) => { return v === r ? 'valid' : 'invalid' }
        })
    }

    buildEmptyState(state) {
        return super.buildEmptyState({
            ...state,
            form: {
                ...state.form,
                password: ''
            },
            passwordConfirm: ''
        })
    }

    getRef(id) {
        return id === 'passwordConfirm' ? this.state.form.password : null
    }

    getFormData() {
        return {
            ...super.getFormData(),
            password: bcrypt.hashSync(this.state.form.password, 12),
            password_hash: 'bcrypt'
        }
    }

    passwordBlock() {
        return (
            <PasswordBlock classes={this.state.className} 
                values={{password:this.state.form.password, passwordConfirm:this.state.passwordConfirm}} 
                onChange={this.onChangeHandler} />
        )
    }
}

export default PasswordForm
