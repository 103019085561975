import React from 'react'
import { FormattedMessage } from 'react-intl';

const newsletterLine = props => {

    return (
        <div className='checkbox-wrapper'>
            <input
                type='checkbox'
                id='newsletter'
                name='newsletter'
                checked={props.checked}
                onChange={props.onChange}
            />
            <label htmlFor='newsletter' className='checkbox-label'>
                <FormattedMessage id="create.newsletter"
                    defaultMessage="I want to receive Aldebaran Newsletter"
                    description="Newsletter message"
                />
            </label>
        </div>
    )
}

export default newsletterLine
