import React, { Component } from 'react';

class notFound extends Component {

    render() {
        
        return (
            <div className='Main'>
                <div className='main-wrapper'>
                    <h1>
                        404 error
                    </h1>
                    <div className='section-wrapper'>
                        <section>
                            <h2>
                                Page not found
                            </h2>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default notFound;