import React, { Component } from 'react';
import './App.css';
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";

import { IntlProvider, addLocaleData } from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';
import locale_ja from 'react-intl/locale-data/ja';
import messages_fr from "./translations/fr.json";
import messages_en from "./translations/en.json";
import messages_ja from "./translations/ja.json";
import getConfig from "./config/getConfig";
import Routes from './routes';

const messages = {
    'en': messages_en,
    'fr': messages_fr,
    'ja': messages_ja,
};

function getDefaultLang() {
    let lang = navigator.language.split(/[-_]/)[0]
    return ["en", "fr", "ja"].indexOf(lang) >= 0 ? lang : "en"
}

const language = getDefaultLang()

const intlConfig = {
    locale: language,
    messages: messages[language]
};

addLocaleData([...locale_en, ...locale_fr, ...locale_ja]);

// window.recaptchaOptions = { useRecaptchaNet: true }

class App extends Component {

    state = {
        cfg: undefined,
        args: undefined,
        locale: language,
    }

    componentWillMount() {
        const queryString = require('query-string-es5');
        const parsed = queryString.parseUrl(window.location.hash);
        const cfg = getConfig(parsed.query)
        this.setState({
            ...this.state,
            cfg: cfg,
            args: parsed.query
        })
    }

    onChangeLanguage(lang) {
        switch (lang) {
            case 'fr': intlConfig.messages = messages['fr']; break;
            case 'en': intlConfig.messages = messages['en']; break;
            case 'ja': intlConfig.messages = messages['ja']; break;
            default: intlConfig.messages = messages['en']; break;
        }
        this.setState({ locale: lang });
        intlConfig.locale = lang;
    }

    render() {

        return (
            <IntlProvider key={intlConfig.locale} locale={intlConfig.locale} messages={intlConfig.messages}>
                <div className='App'>
                    <Header onChangeLanguage={(lang) => this.onChangeLanguage(lang)} {...this.state} />
                    <Routes {...this.state} />
                    <Footer {...this.state} />
                </div>
            </IntlProvider>
        );
    }
}

export default App;
