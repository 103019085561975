import React, { Component } from 'react';
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import Create from "./components/Create/create";
import Forgot from "./components/Forgot/forgot";
import Reset from "./components/Reset/reset";
import NotFound from "./components/NotFound/notFound";

class Routes extends Component {
    render() {
        return (
            <HashRouter>
                <React.Fragment>
                    <Switch>
                        <Route exact path="/create" render={(props) => <Create {...this.props} />} />
                        <Route exact path="/forgot" render={(props) => <Forgot {...this.props} />} />
                        <Route exact path="/reset" render={(props) => <Reset {...this.props} />} />
                        <Route exact path="/404" component={NotFound} />
                        <Route exact path="/" component={NotFound} />
                        <Redirect to="/404" />
                    </Switch>
                </React.Fragment>
            </HashRouter>
        );
    }
}

export default Routes;
