import React from 'react';
import youtubeLogo from "../../../assets/img/footer/logo-youtube-white.svg";

const youtubeButton = props => {
    return (
        <React.Fragment>
            {
                props.href &&
                <a className="no-click-out-icon" href={props.href} target="_blank" rel="noopener noreferrer">
                    <img src={youtubeLogo} alt="youtube" />
                </a>
            }
        </React.Fragment>
    );
};

export default youtubeButton;
