import address from './address.json'

const data = address;

export function getCountries(locale) {
    return data[locale].country || data.default.country;
}

export function getRegions(locale, country) {
    return data[locale].region[country] || data.default.region[country];
}
