import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import PasswordForm  from '../Common/passwordForm'
import NameBlock from './UI/nameBlock'
import CompanyLine from './UI/companyLine'
import PostalBlock from './UI/postalBlock'
import NewsletterLine from './UI/newsletterLine'
import TermsLine from './UI/termsLine'


function isNameValid(value) {
    return value && value.trim && value.trim() === value
}

function stringClassName(value) {
    return isNameValid(value) ? 'valid' : 'invalid'
}

function optionalStringClassName(value) {
    return value.length ? stringClassName(value) : ''
}

class Create extends PasswordForm {

    buildClassNamer(namer) {
        return super.buildClassNamer({ ...namer,
            last_name: stringClassName,
            first_name: stringClassName,
            company_name: optionalStringClassName,
            country: stringClassName,
            state: (v, c) => { return ['US', 'CA', 'CN'].indexOf(c) >= 0 ? (v ? 'valid' : 'invalid') : '' },
            city: stringClassName,
            address: stringClassName,
            postal_code: stringClassName
        })
    }
    
    buildEmptyState(state) {
        return super.buildEmptyState({
            ...state,
            form: {
                ...state.form,
                last_name: '',
                first_name: '',
                company_name: '',
                country: '',
                state: '',
                city: '',
                address: '',
                postal_code: ''
            },
            newsletter: false,
            agree: false
        })
    }

    onCountryChanged = (item) => {
        const newCountry  = (item && item.value) || ''
        this.setState({
            form: {
                ...this.state.form,
                country: newCountry,
                state: ''
            },
            className: {
                ...this.state.className, 
                country: this.classNamer.country(newCountry),
                state: this.classNamer.state('', newCountry)
            }
        })
    }

    onStateChanged = (item) => {
        const newState = (item && item.value) || ''
        this.setState({
            form: {
                ...this.state.form,
                state: newState,
            },
            className: {
                ...this.state.className, 
                state: this.classNamer.state(newState, this.state.form.country)
            }
        })
    }

    getFormData() {
        return {
            ...super.getFormData(),
            newsletter:  this.state.newsletter.toString()
        }
    }

    render() {
        return (
            <div className='Main'>
                <div className='main-wrapper'>
                    <h1>
                        <FormattedMessage id="create.title"
                            defaultMessage="Create your new account"
                            description="Title on app main page"
                        />
                    </h1>
                    <div className='section-wrapper'>
                        <section name='create'>
                        <h2>
                            <FormattedMessage id="create.header"
                                defaultMessage="Please fill your account details below."
                                description="Header on the account creation page"
                            />
                        </h2>
                        <form onSubmit={this.onSubmitHandler} className='form'>
                            <NameBlock lastNameRef={e => this.defaultControl = e}
                                values={this.state.form}
                                classes={this.state.className}
                                onChange={this.onChangeHandler}
                            />
                            { this.emailLine(false)  }
                            { this.passwordBlock() }
                            <CompanyLine show={this.props.cfg.company}
                                locale={this.props.intl.locale}
                                value={this.state.form.company_name}
                                className={this.state.className.company_name}
                                onChange={this.onChangeHandler}
                            />
                            <PostalBlock locale={this.props.intl.locale}
                                cfg={this.props.cfg}
                                values={this.state.form}
                                classes={this.state.className}
                                onCountryChanged={this.onCountryChanged}
                                onStateChanged={this.onStateChanged}
                                onChange={this.onChangeHandler}
                            />
                            { this.props.cfg.newsletter && <NewsletterLine checked={this.state.newsletter} onChange={this.onChangeHandler} /> }
                            <TermsLine checked={this.state.agree} onChange={this.onChangeHandler} />
                            { this.commonWidgets('create') }
                        </form>
                        </section>
                    </div>
                </div>
            </div >
        )
    }
}

export default injectIntl(Create);