import React from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'

import { getRegions } from '../../../translations/address'


const stateCombo = props => {

    if (!props.states) return null
    
    return (
            <Select name='state-select'
                options=
                {
                    props.states.map(function(e) {return {value:e[0],label:e[1]}})
                }
                value={props.value}
                className={props.className}
                onChange={props.onChange}
                noResultsText={null}
                placeholder={null}
                multi={false}
                required={props.required}
            />
    )
}

const stateLine = props => {

    const states = getRegions(props.locale, props.country)
    if (!states) return null
    const asterix = props.required ? <span className='mandatory-field'>*</span> : null

    return (
        <div className='input-wrapper'>
            <label htmlFor='state'>
                <FormattedMessage id="create.state"
                    defaultMessage="State"
                    description="State input"
                />&nbsp;{asterix}</label>
            {stateCombo({...props, states: states}) }
        </div>
    );
}

export default stateLine
