import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PasswordForm from '../Common/passwordForm'
import axios from 'axios';
import axiosRetry from 'axios-retry';
import './reset.css'

class Reset extends PasswordForm {

    buildEmptyState(state) {
        return super.buildEmptyState({
            ...state,
            linkValidity: 'checking'
        })
    }

    componentDidMount() {
        this.checkLinkValidity()
    }

    componentWillUnmount() {
        this.cancelLinkValidation()
    }

    cancelLinkValidation() {
        if (typeof this._linkValidationCall !== typeof undefined) {
            this._linkValidationCall.cancel('Link validation call cancelled')
            this._linkValidationCall = undefined
        }
    }

    checkLinkValidity() {
        this.cancelLinkValidation()

        this.setState({ linkValidity: 'checking' })

        axiosRetry(axios, {
            retries: 2,
            delay: function (retryCount) {
                return 100
            }
        })

        this._linkValidationCall = axios.CancelToken.source();

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            cancelToken: this._linkValidationCall.token
        }

        axios.post(this.props.cfg.proxy + 'argcheck/', { args: this.props.args }, axiosConfig)
            .then((res) => {
                let validity = (res.data && res.data.status === 'valid') ? 'valid' : 'invalid'
                this.setState({linkValidity: validity})
            })
            .catch((err) => {
                if (!axios.isCancel(err)) {
                    this.setState({linkValidity: 'invalid'})
                }
            })
    }

    onClearForm(event) {
        super.onClearForm(event)
        this.checkLinkValidity()
    }

    getFormData() {
        return {
            ...super.getFormData(),
            args: this.props.args
        }
    }

    getSubmitInfo() {
        return { url: this.props.cfg.proxy + 'password/', verb: 'put' } 
    }

    onSucceeded() {
        let redirect = this.props.cfg.redirect_uri || this.props.cfg.redirect
        if (redirect)
            window.location = redirect
    }

    goToForget() {
        window.location.hash = '#/forgot'
    }

    render() {
        let headerId = "reset.header." + (this.state.linkValidity || "checking")
        return (
            <div className='Main'>
                <div className='main-wrapper'>
                    <h1>
                        <FormattedMessage id="reset.title"
                            defaultMessage="Reset your password"
                            description="Main title on reset password page"
                        />
                    </h1>
                    <div className='section-wrapper long-desc-wrapper'>
                        <section className='forgot'>
                            <h2>
                                <FormattedMessage id={headerId}
                                    defaultMessage=""
                                    description="Header title on reset password page"
                                />
                            </h2>
                            { this.state.linkValidity === 'valid' &&
                                <form onSubmit={this.onSubmitHandler} className='form'>
                                    <div className="form-content-wrapper">
                                        { this.emailLine(true) }
                                        { this.passwordBlock() }
                                        { this.commonWidgets('reset') }
                                    </div>
                                </form>
                            }
                            { this.state.linkValidity === 'invalid' &&
                                <div className='buttons-wrapper'>
                                    <button type='button' onClick={this.goToForget.bind(this)} className='wide-button'>
                                        <FormattedMessage id="reset.newemail"
                                            defaultMessage="Get new mail"
                                            description="Forgot button text"
                                        />
                                    </button>
                                </div>
                            }
                        </section>
                    </div>
                </div>
            </div >
        );
    }
}

export default injectIntl(Reset);
