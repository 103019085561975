import React, { Component } from 'react';

import logoType from "./../../assets/img/header-logo.svg";

import FacebookButton from "./UI/facebookButton";
import InstagramButton from "./UI/instagramButton";
import YoutubeButton from "./UI/youtubeButton";
import MediumButton from "./UI/mediumButton";
import TwitterButton from "./UI/twitterButton";
import LinkedinButton from "./UI/linkedinButton";

class Footer extends Component {
    render() {
        return (
            <footer id="footer" role="contentinfo" className="footer">
                <div className="footer-inner">
                    <aside className="footer-links">
                        <div className="hdg-container">
                            <h1 className="hdg"><img src={logoType} alt="Aldebaran" /></h1>
                            <p className="description">This page is administered by Aldebaran &copy;{new Date().getFullYear()}</p>
                        </div>
                        <ul className="footer-link-list">
                            <li className="custom-site-content" id="footer__contents">
                                {this.props.cfg.social &&
                                    <div id="footer-menus-container">
                                        <div className="footer-social-menu">
                                        <FacebookButton href={this.props.cfg.facebook} />
                                        <TwitterButton href={this.props.cfg.twitter} locale={this.props.locale} />
                                        <InstagramButton href={this.props.cfg.instagram} />
                                        <YoutubeButton href={this.props.cfg.youtube} />
                                        <MediumButton href={this.props.cfg.medium} />
                                        <LinkedinButton href={this.props.cfg.linkedin} />
                                        </div>
                                    </div>
                                }
                            </li>
                        </ul>
                    </aside>
                </div>
            </footer>
        );
    }
}

export default Footer;
