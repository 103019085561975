import React from 'react';
import facebookLogo from "../../../assets/img/footer/logo-facebook-white.svg";

const facebookButton = props => {
    return (
        <React.Fragment>
            {
                props.href &&
                <a className="no-click-out-icon" href={props.href} target="_blank" rel="noopener noreferrer">
                    <img src={facebookLogo} alt="Facebook" />
                </a>
            }
        </React.Fragment>
    );
};

export default facebookButton;
