import React, { Component } from 'react';
import headerLogo from '../../assets/img/header-logo.svg';
import ContactButton from "./UI/contactButton";


class Header extends Component {

    render() {

        return (
            <header id="header" role="banner">
                <div id="main-menu" role="navigation">
                    <div className="menu-navigation-container">
                        <a className="no-click-out-icon" id="header-logo-link" href={this.props.cfg.main}>
                            <img className="header-logo" src={headerLogo} alt="Aldebaran" />
                        </a>
                        <ContactButton {...this.props} />
                    </div>
                    {
                        this.props.cfg.langbar &&
                        <div className="secondary-menu desktop-menu">
                            <ul>
                                <li id='en' onClick={(lang) => this.props.onChangeLanguage('en')}>EN</li>
                                <li id='fr' onClick={(lang) => this.props.onChangeLanguage('fr')}>FR</li>
                                <li id='jp' onClick={(lang) => this.props.onChangeLanguage('ja')}>JP</li>
                            </ul>
                        </div>
                    }
                </div>
            </header>
        );
    }
}

export default Header;
