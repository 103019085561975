import React from 'react'
import { FormattedMessage } from 'react-intl';

const noTrailingBlanksPattern = "^\\S+(?:\\s+\\S+)*$"

const companyLine = props => {

    if (!props.show)
        return null

    return (
        <React.Fragment>
            <div className='input-wrapper'>
                <label htmlFor='company_name'>
                    <FormattedMessage id="create.company"
                        defaultMessage="Company"
                        description="Company input"
                    /><span>&nbsp;&nbsp;</span></label>
                <input
                    type='text'
                    className={props.className}
                    id='company_name'
                    maxLength='80'
                    onChange={props.onChange}
                    autoComplete='organization'
                    value={props.value}
                    pattern={noTrailingBlanksPattern}
                    />
            </div>
                <div className='input-wrapper'>
                    <div className='tooltip company_name'>
                        <FormattedMessage id="create.companyTip"
                            defaultMessage="Leave blank if you're an individual"
                            description="company tip"
                        />
                    </div>
                </div>
        </React.Fragment>
    )
}

export default companyLine