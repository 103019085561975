import unicat from './unicat.json'
const beg = unicat.beg

function findIndex(cp, u, j, v) {
    do {
        if (cp < beg[j])
            v = j-1
        else if (cp < beg[j+1])
           return unicat.cat[j]
        else
            u = j+1
        j = (u+v) >> 1
    } while (v>u)
    return unicat.cat[j]
}

export function codePointCat(cp) {
    if (cp<0) return unicat.cat[0]
    if (cp>=beg.slice(-1)[0]) return unicat.cat.slice(-1)
    return findIndex(cp, 0, 11, beg.length-1)
}
