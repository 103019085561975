import React, { Component } from 'react'

import axios from 'axios';
import axiosRetry from 'axios-retry';
import EmailValidator from 'email-validator';

import { emailLine as EmailLine, commonCaptchaFormWidgets } from './UI/common'


export function isEmailValid(email) {
    return email && EmailValidator.validate(email)
}


class EmailCaptchaForm extends Component {

    constructor(props) {
        super(props)
        
        this.classNamer = this.buildClassNamer({
            email: (v) => { return v ? (isEmailValid(v) ? 'valid' : 'invalid') : '' }
        })

        this.state = this.emptyState()
    }

    buildClassNamer(namer) {
        return namer
    }

    emptyState() {

        let state = this.buildEmptyState({
            form: {
                email: ''
            },
            className: {
            },
            recaptcha: false,
            error: '',
            disableButtons: false
        })

        for (let k in this.classNamer)
            state.className[k] = '' // this.classNamer[k](state[k] || state.form[k] || '')

        return state
    }

    buildEmptyState(state) {
        return state
    }

    componentDidMount() {
        this.defaultControl && this.defaultControl.focus()
    }

    getRef(id) {
        return null
    }

    onChangeHandler = event => {

        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const id = event.target.id

        if (id in this.state.form) 
            this.setState({ form: {...this.state.form, [id]: value }})
        else 
            this.setState({ [id]: value })

        if (id in this.state.className) {
            this.setState({ className: { ...this.state.className, [id]: this.classNamer[id](value, this.getRef(id)) } })
        }

    }

    onRecaptchaHandler = value => {
        if (value !== this.state.recaptcha) {
            this.setState({
                recaptcha: value,
                error: null
            })
        }
    }

    onClearForm(event) {
        event.preventDefault();

        this.setState(this.emptyState())
        this.recaptcha.reset()

        this.defaultControl && this.defaultControl.focus()
    }

    getFormData() {
        return this.state.form
    }

    getSubmitInfo() {
        return { url: this.props.cfg.proxy, verb: 'post' } 
    }

    onSucceeded() {
        if (this.props.cfg.redirect) window.location = this.props.cfg.redirect
    }

    onSubmitHandler = event => {
        event.preventDefault();

        for (const v in this.state.className) {
            if (this.state.className[v] === 'invalid') {
                const child = document.getElementById(v)
                child && child.focus()
                return
            }
        }

        if (!this.state.recaptcha) {
            this.setState({
                error: this.props.intl.formatMessage({id: "main.error"})
            })
            return
        }

        axiosRetry(axios, {
            retries: 2,
            delay: function (retryCount) {
                return 100
            }
        })

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Recaptcha-Id': this.state.recaptcha
            }
        }

        const data = this.getFormData()
        const submit = this.getSubmitInfo()

        this.setState({ disableButtons: true })

        axios[submit.verb](submit.url, data, axiosConfig)
            .then((res) => {
                this.onSucceeded()
            })
            .catch((err) => {
                this.setState({
                    error: err.response && err.response.data && err.response.data.message
                })
            })
            .then(() => {
                this.setState({
                    disableButtons: false
                })
                this.recaptcha && this.recaptcha.reset()
            })
    }

    emailLine(isDefault) {

        return <EmailLine
                    emailRef={isDefault ? ( e => this.defaultControl = e) : null}
                    value={this.state.form.email}
                    className={this.state.className.email}
                    onChange={this.onChangeHandler}
                />
    }

    commonWidgets(name) {
        return commonCaptchaFormWidgets({
            captchaRef: e => this.recaptcha = e,
            sitekey: this.props.cfg.captcha,
            onCaptchaChange: this.onRecaptchaHandler,
            error: this.state.error,
            cancelMessage: "main.cancel",
            submitMessage: name + ".send",
            disableButtons: this.state.disableButtons,
            onCancel: this.onClearForm.bind(this)
        })
    }
}

export default EmailCaptchaForm
